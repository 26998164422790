import { CSSProperties } from "react";

import cn from "classnames";
import { Control, Controller, FieldPathValue, Path } from "react-hook-form";

import { FormSchemaCommonFields } from "@/constants/validateSchema";

import style from "./CheckboxField.module.css";

export interface CheckboxFieldProps<T extends FormSchemaCommonFields> {
  /** Стили для контейнера компонента Checkbox */
  classNameContainerCheckbox?: CSSProperties;
  /** Флаг, true если поле обязательное */
  isRequiredField?: boolean;
  /** Уникальный ключ формы */
  name: Path<T>;
  /** Функция из react-hook-form, используемая для регистрации поля ввода в форме. */
  control: Control<T>;
  /** Лейбл чекбокса */
  titleCheckbox: string;
  defaultValue?: FieldPathValue<T, any>;
  value?: any;
  children?: React.ReactNode;
  defaultChecked?: boolean;
}
export function CheckboxField<T extends FormSchemaCommonFields>({
  name,
  classNameContainerCheckbox,
  control,
  titleCheckbox,
  isRequiredField,
  defaultValue,
  value,
  children,
  defaultChecked,
}: CheckboxFieldProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequiredField }}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <label className={cn(style.container, classNameContainerCheckbox)}>
            <input
              {...field}
              value={value === null ? "" : value}
              type="checkbox"
              className={style.checkbox}
              defaultChecked={defaultChecked}
            />
            <div className={style.checkmark}></div>

            {children ? (
              children
            ) : (
              <span className={style.label}>{titleCheckbox}</span>
            )}
          </label>
        );
      }}
    />
  );
}
